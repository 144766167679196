import {
  Chart,
  ChartConfiguration,
  RadarController,
  RadialLinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Filler
} from 'chart.js';
import $ from 'jquery';
import { Sender } from './common/sender';
import { addGood, removeGood } from './common/vote_utils';

Chart.register(RadialLinearScale);
Chart.register(RadarController);
Chart.register(PointElement);
Chart.register(LineElement);
Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(BarController);
Chart.register(BarElement);
Chart.register(Filler);
if (document.body.id === "channel") {
  const statusCanvas = document.getElementById("statusChart") as HTMLCanvasElement;
  if (statusCanvas === null) {
    // エラーハンドリング
    throw new Error('Canvas element is not found');
  }
  const genderCanvas = document.getElementById("genderChart") as HTMLCanvasElement;
  if (genderCanvas === null) {
    // エラーハンドリング
    throw new Error('Canvas element is not found');
  }
  const toneCanvas = document.getElementById("toneChart") as HTMLCanvasElement;
  if (toneCanvas === null) {
    // エラーハンドリング
    throw new Error('Canvas element is not found');
  }

  const createStatusChart = function (votes: number[]) {
    const data = {
      labels: ["キュート", "クール", "ファニー", "パワフル", "セクシー"],
      datasets: [
        {
          data: votes,
          fill: true,
          backgroundColor: "rgba(111, 186, 123, 0.2)",
          borderColor: "rgb(111, 186, 123)",
          pointBackgroundColor: "rgb(111, 186, 123)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgb(111, 186, 123)",
        },
      ],
    };

    const config: ChartConfiguration = {
      type: "radar",
      data: data,
      options: {
        scales: {
          r: {
            angleLines: {
              display: false,
            },
            suggestedMin: 0,
            suggestedMax: Math.max(...votes),
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        elements: {
          line: {
            borderWidth: 3,
          },
        },
      },
    };

    return new Chart(statusCanvas, config);
  };

  const createGenderChart = function (votes: number[]) {
    const genderConfig: ChartConfiguration = {
      type: "bar",
      data: {
        labels: [""],
        datasets: [
          {
            label: "女性的",
            data: [votes[0]],
            backgroundColor: "rgba(111, 186, 123, 0.2)",
            borderColor: "black",
          },
          {
            label: "男性的",
            data: [votes[1]],
            backgroundColor: "rgba(126, 71, 228, 0.2)",
            borderColor: "black",
          },
        ],
      },
      options: {
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
        responsive: false,
        // title: {
        //   display: false,
        //   fontSize: 20,
        // },
        plugins: {
          legend: {
            display: true,
            onClick: function (evt, item) {
              // console.log("legend onClick", evt, item);
            },
          },
        },
        indexAxis: "y",
      },
    };

    return new Chart(genderCanvas, genderConfig);
  };

  const createToneChart = function (votes: number[]) {
    const toneConfig: ChartConfiguration = {
      type: "bar",
      data: {
        labels: [""],
        datasets: [
          {
            label: "高音系",
            data: [votes[1]],
            backgroundColor: "rgba(242, 104, 5, 0.2)",
            borderColor: "black",
          },
          {
            label: "低音系",
            data: [votes[0]],
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            borderColor: "black",
          },
        ],
      },
      options: {
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
        responsive: false,
        // title: {
        //   display: false,
        //   fontSize: 20,
        // },
        plugins: {
          legend: {
            display: true,
            onClick: function (evt, item) {
              // console.log("legend onClick", evt, item);
            },
          },
        },
        indexAxis: "y",
      },
    };

    return new Chart(toneCanvas, toneConfig);
  };

  class ChartData {
    public votes: number[];
    constructor(dataNames: string[]) {
      this.votes = [];
      for (var name of dataNames) {
        this.votes.push($(name).data("sum"));
      }
    }

    setValue(name: string, value: number): void {
      var indexMap: Record<string, number> = {
        cute: 0,
        cool: 1,
        funny: 2,
        powerful: 3,
        sexy: 4,
      };
      let index: number = indexMap[name];
      let current: number = this.votes[index];
      this.votes.splice(index, 1, current + value);
    }

    get() {
      return this.votes;
    }

    exist() {
      for (var i of this.votes) {
        if (i != 0) {
          return true;
        }
      }
      return false;
    }
  };

  let satusChartData = new ChartData([
    '[name="cute"]',
    '[name="cool"]',
    '[name="funny"]',
    '[name="powerful"]',
    '[name="sexy"]',
  ]);
  let genderChartData = new ChartData(["#genderWomen", "#genderMen"]);
  let toneChartData = new ChartData(["#toneLow", "#toneHigh"]);

  class UtaiteChart {
    private chartCanvas;
    private chartNoData;
    public chartData;
    private chart: Chart | null;
    private createChartFunction;
    private isShown;
    constructor(id: string, chartData: ChartData, createChartFunction: Function) {
      this.chartCanvas = $(id);
      this.chartNoData = $(id + "NoData");
      this.chartData = chartData;
      this.chart = null;
      this.createChartFunction = createChartFunction;
      this.isShown = false;
    }

    show() {
      this.chartCanvas.removeClass("d-none");
      this.chartNoData.addClass("d-none");
      this.chart = this.createChartFunction(this.chartData.get());
      this.isShown = true;
    }

    update() {
      if (this.chart !== null) {
        this.chart.destroy();
      }
      this.chart = this.createChartFunction(this.chartData.get());
    }

    update_or_show() {
      if (this.isShown) {
        this.update();
      } else {
        this.show();
      }
    }

    update_or_hidden() {
      if (this.chartData.exist()) {
        this.update();
      } else {
        this.hidden();
      }
    }

    update_or_show_or_hidden() {
      if (this.chartData.exist()) {
        if (this.isShown) {
          this.update();
        } else {
          this.show();
        }
      } else {
        this.hidden();
      }
    }

    hidden() {
      this.chartNoData.removeClass("d-none");
      this.chartCanvas.addClass("d-none");
      if (this.chart != null) {
        this.chart.destroy();
        this.chart = null;
      }
      this.isShown = false;
    }
  };

  let statusChart = new UtaiteChart(
    "#statusChart",
    satusChartData,
    createStatusChart
  );
  let genderChart = new UtaiteChart(
    "#genderChart",
    genderChartData,
    createGenderChart
  );
  let toneChart = new UtaiteChart("#toneChart", toneChartData, createToneChart);

  let CharManager = class {
    private charts;
    constructor(charts: UtaiteChart[]) {
      this.charts = charts;
    }

    init() {
      for (var chart of this.charts) {
        if (chart.chartData.exist()) {
          chart.show();
        } else {
          chart.hidden();
        }
      }
    }
  };

  var chartManager = new CharManager([statusChart, genderChart, toneChart]);

  chartManager.init();

  const fd = new FormData();

  const creatorId = $("#creatorData").data("creatorid");
  let radioVal: Record<string, number> = {};

  let send = function () {
    const csrfmiddlewaretoken = $('input[name="csrfmiddlewaretoken"]').val() as string;
    fd.set("csrfmiddlewaretoken", csrfmiddlewaretoken);
    fd.set("creator_id", creatorId);

    $('button.status-btn').each(function () {
      const btnval = $(this).val() as string;
      fd.set($(this).attr("name") as string, btnval);
    });

    for (let key in radioVal) {
      fd.set(key, radioVal[key].toString());
    }

    fetch($("#statusCharts").data("api"), {
      method: "POST",
      body: fd,
    }).then((response) => {
      if (response.ok) {
        console.log("ok");
      } else {
        console.log("error");
      }
    });
  };

  let sender = new Sender(send);

  $("button.status-btn").on("click", function () {
    const btnval = $(this).val() as string;
    let name = $(this).attr("name") as string;
    if (parseInt(btnval) == 0) {
      $(this).val(1);
      addGood($(this).find("i"));
      statusChart.chartData.setValue(name, 1);
      statusChart.update_or_show();
    } else {
      $(this).val(0);
      removeGood($(this).find("i"));
      statusChart.chartData.setValue(name, -1);
      statusChart.update_or_hidden();
    }
    sender.setSendValueTimer();
  });

  let radioOnClick = function (query: string, chart: UtaiteChart) {
    let queryName = $(query).attr("name") as string;
    radioVal[queryName] = $(query).filter(":checked").val() as number;
    $(query).on("click", function () {
      let name = $(this).attr("name") as string;
      var val = $(this).val() as number;
      if (radioVal[name] == val) {
        $(this).prop("checked", false);
        chart.chartData.votes[val]--;
      } else {
        chart.chartData.votes[val]++;
        if (radioVal[name] != undefined) {
          chart.chartData.votes[val == 1 ? 0 : 1]--;
        }
      }

      for (var elem of $(query).toArray()) {
        var id = $(elem).attr("id");
        var label = $('label[for="' + id + '"]').find("i");
        if ($(elem).prop("checked")) {
          addGood(label);
        } else {
          removeGood(label);
        }
      }
      chart.update_or_show_or_hidden();

      radioVal[name] = $(query).filter(":checked").val() as number;
      sender.setSendValueTimer();
    });
  };

  radioOnClick('input[name="gender"]:radio', genderChart);
  radioOnClick('input[name="tone"]:radio', toneChart);

}