import { alertModal } from './modal';

export function sendRankingReport(event: MouseEvent, rankingName: string, rankerName: string) {
    const clickedElement = event.target as HTMLElement | null;
    if (!clickedElement) {
        console.error("Event target is not an HTMLElement");
        return;
    }

    const url = clickedElement.getAttribute("data-api");
    if (!url) {
        console.error("URL not found in data-api attribute");
        return;
    }

    const fd = new FormData();
    const csrfmiddlewaretoken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '';
    if (!csrfmiddlewaretoken) {
        console.error("CSRF token not found");
        return;
    }

    fd.set("csrfmiddlewaretoken", csrfmiddlewaretoken);
    fd.set("ranking_name", rankingName);
    fd.set("ranker_name", rankerName);

    fetch(url, {
        method: "POST",
        body: fd,
    })
        .then((response) => {
            if (response.ok) {
                console.log("Request succeeded");
            } else {
                console.error("Request failed with status:", response.status);
            }
        })
        .catch((error) => {
            console.error("Fetch error:", error);
        });

    alertModal("完了", "報告ありがとうございました！");
};

