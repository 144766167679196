import { Sender } from './common/sender';
import $ from 'jquery';
import * as bootstrap from 'bootstrap';
import { createApp } from 'vue';
import { sendRankingReport } from './common/report';

if (document.body.id === "song") {
    let sender: Sender;
    const app = createApp({
        delimiters: ['[[', ']]'],
        data() {
            return {
                allTags: (window as any).SONG_TAGS,
                primaryTags: [],
                secondaryTags: [],
                songTagVote: (window as any).SONG_TAG_VOTE
            };
        },
        mounted() {
            this.initGood();
            this.classify();
            sender = new Sender(this.send);
        },
        updated() {
            // tooltipが残る不具合があるので削除
            const tooltips = Array.from(document.querySelectorAll('.tooltip'));
            tooltips.forEach((tooltip: Element) => {
                tooltip.remove();
            });

            const tooltipTriggerList = Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'));

            // BootstrapのTooltipインスタンスを作成
            const tooltipList = tooltipTriggerList.map((tooltipTriggerEl: Element) => {
                return new bootstrap.Tooltip(tooltipTriggerEl)
            });
        },
        methods: {
            click(tag: any) {
                console.log(tag.id);
                let idx = this.songTagVote.indexOf(tag.id);
                if (idx === -1) {
                    this.songTagVote.push(tag.id);
                    tag.votes_count++;
                } else {
                    this.songTagVote = this.songTagVote.filter((id: number) => id !== tag.id);
                    tag.votes_count--;
                }
                sender.setSendValueTimer();
                tag.good = !tag.good;

                this.allTags.sort(function (a: any, b: any) {
                    return a.votes_count - b.votes_count;
                });

                this.classify();
            },
            send() {
                const fd = new FormData();
                let csrfToken = $('meta[name="csrf-token"]').attr('content') as string;
                fd.set("csrfmiddlewaretoken", csrfToken);
                fd.set("songid", $("#songTags").data("songid"));
                this.songTagVote.forEach((goodSongTagId: number) => {
                    fd.append("songtagids", goodSongTagId.toString());
                });
                fetch($("#songTags").data("api"), {
                    method: "POST",
                    body: fd,
                });
            },
            initGood() {
                for (let i = 0; i < this.allTags.length; i++) {
                    if (this.songTagVote.includes(this.allTags[i].id)) {
                        this.allTags[i].good = true;
                    } else {
                        this.allTags[i].good = false;
                    }
                }
            },
            classify() {
                let cnt = 0;
                this.primaryTags = [];
                this.secondaryTags = [];
                for (let tag of this.allTags) {
                    if (tag.votes_count > 0 && cnt < 6) {
                        this.primaryTags.push(tag);
                        cnt++;
                    } else {
                        this.secondaryTags.push(tag);
                    }
                }
            },
            reportClick(event: MouseEvent) {
                const target = event.target as HTMLElement;
                const utaiteCard = target.closest('.utaite-card');
                this.rankingName = document.querySelector('#main')?.getAttribute("data-song-title");
                this.rankerName = utaiteCard?.querySelector('.card-title')?.innerHTML;
            },
            reportModalClick(event: MouseEvent) {
                sendRankingReport(event, this.rankingName, this.rankerName);
            }
        }
    });

    app.mount('#song');
}

