import 'jquery';

function addGood(obj: JQuery<HTMLElement>) {
    obj.removeClass("far");
    obj.addClass("fas");
};

function removeGood(obj: JQuery<HTMLElement>) {
    obj.removeClass("fas");
    obj.addClass("far");
};

export { addGood, removeGood };