import { createApp } from 'vue';
import { int2kanji } from './common/utils';
import { sendRankingReport } from './common/report';

if (document.body.id === "vocaloid-views-ranking" || document.body.id === "index") {

    let csrfToken = $('meta[name="csrf-token"]').attr('content');
    let periodid = (window as any).PERIOD_ID;
    let utattemitaListApi = (window as any).UTATTEMITA_LIST_API;
    let vocaloidViewsRankingApi = (window as any).VOCALOID_VIEWS_RANKING_API;

    let vocaloidRankngMaxView = (window as any).VOCALOID_RANKNG_MAX_VIEW;
    let loadLimit = (window as any).VOCALOID_RANKNG_MIN_VIEW;
    let startPage = (window as any).START_PAGE;

    let loadCnt = vocaloidRankngMaxView / loadLimit;
    if (document.body.id === "index") {
        loadLimit = 3;
        loadCnt = 1;
    }
    let loads: (string | undefined)[] = []
    const app = createApp({
        delimiters: ['[[', ']]'],
        data() {
            return {
                rankings: [],
                utattemita: {},
                isLoading: false,
                page: startPage,
            };
        },
        mounted() {
            this.createObserver();
            let rankingItems = document.getElementById("rankingItems");
            if (rankingItems) {
                rankingItems.hidden = false;
            }
            this.showCollapse()
        },
        beforeUnmount() {
            // コンポーネントが破棄される前にオブザーバーを解除
            if (this.observer) {
                this.observer.disconnect();
            }
        },
        updated() {
            // dataが更新されたら実行される

            this.showCollapse()
        },
        methods: {
            /**
             * ロードを監視して画面に入ったら追加のアイテムを読み込む
             */
            createObserver() {
                const options = {
                    root: null,
                    rootMargin: '0px',
                    threshold: 0.1
                };

                // コールバック関数
                const callback = (entries: any[]) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            this.loadMoreItems();
                        }
                    });
                };

                // Observerのインスタンスを作成
                this.observer = new IntersectionObserver(callback, options);

                // 監視対象のエレメントを監視
                this.observer.observe(this.$refs.loadElem);
            },
            loadMoreItems() {
                if (this.isLoading) return;
                if (this.page >= loadCnt) return;
                this.isLoading = true;
                this.page += 1;
                $.ajax({
                    url: `${vocaloidViewsRankingApi}?page=${this.page}`,
                    type: 'POST',
                    data: {
                        csrfmiddlewaretoken: csrfToken,
                        periodid: periodid,
                        limit: loadLimit,
                    },
                    dataType: 'json',
                    success: res => {
                        this.rankings = this.rankings.concat(res);
                        this.isLoading = false;
                    },
                    error: (xhr, status, error) => {
                        console.error(error);
                        this.isLoading = false;
                    }
                });
            },
            getUtattemitaRanking(songId: number) {
                return this.utattemita[songId];
            },
            existUtattemitaRanking(songId: number) {
                if (this.utattemita[songId] === undefined) {
                    return false;
                }
                return this.utattemita[songId].length > 0;
            },
            int2kanji(value: number) {
                return int2kanji(value)
            },
            reportClick(event: MouseEvent) {
                const target = event.target as HTMLElement;
                const item = target.closest('.vocaloid-ranking-item');
                const utaiteCard = target.closest('.utaite-card');
                this.rankingName = item?.querySelector('.title')?.innerHTML;
                this.rankerName = utaiteCard?.querySelector('.card-title')?.innerHTML;
            },
            reportModalClick(event: MouseEvent) {
                sendRankingReport(event, this.rankingName, this.rankerName);
            },
            showCollapse() {
                let handleClickEvent = (t: any) => {
                    let id: string = t.srcElement.getAttribute('id')
                    let videoId: string = t.srcElement.getAttribute('data-id')
                    let songId: string = t.srcElement.getAttribute('data-songid')
                    if (!loads.includes(songId)) {
                        loads.push(songId)
                        $(`#${id} .accordion-body .yt-wrapper`).append(`<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`);
                        console.log("start")
                        $.ajax({
                            url: utattemitaListApi,
                            type: 'POST',
                            data: {
                                csrfmiddlewaretoken: csrfToken,
                                songid: songId,
                                limit: 3,
                            },
                            dataType: 'json',
                            success: data => {
                                this.utattemita[songId] = data
                                let utattemitaListFirst = document.getElementById(`utattemitaListFirst${songId}`);
                                console.log(utattemitaListFirst)
                                if (utattemitaListFirst) {
                                    utattemitaListFirst.hidden = false;
                                }
                            },
                            error: (xhr, status, error) => {
                                console.error(error);
                            }
                        });
                    }
                }

                var collapseElementList = [].slice.call(document.querySelectorAll('.collapse'));
                collapseElementList.forEach(function (collapseEl: HTMLElement) {
                    collapseEl.removeEventListener('show.bs.collapse', handleClickEvent);
                });

                collapseElementList.forEach(function (collapseEl: HTMLElement) {
                    collapseEl.addEventListener('show.bs.collapse', handleClickEvent);
                });
            },
        },
    });
    app.mount('#VocaloidViewsRanking');
}