import $ from 'jquery';
import { inherits } from 'util';

class Sender {
    public timer: NodeJS.Timeout | null;
    public send: () => void;
    constructor(send: () => void) {
        this.timer = null;
        this.send = send;
        // 画面遷移時に送信する
        $(window).on("beforeunload", () => {
            if (this.timer != null) {
                send();
                clearTimeout(this.timer);
            }
        });
    }

    // タイマーを設定して、5秒後に送信する
    // 連打対策
    setSendValueTimer() {
        if (this.timer != null) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(this.send, 5000);
    };
}


export { Sender };