
export function int2kanji(value: number) {
    if (value == undefined || value == null) {
        return ''
    }

    let res = ''
    const OKU = 100000000
    const MAN = 10000
    if (value >= OKU) {
        res = `${Math.floor(value / OKU)}億`
    }
    value %= OKU
    if (value >= MAN) {
        return res + `${Math.floor(value / MAN)}万`
    }

    return value
}

export function getTextWidth(text: string, font = "16px Arial") {
    // Canvas要素を作成
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // フォントを指定（指定しない場合はデフォルトで"16px Arial"）
    if (context) {
        context.font = font;
        // 指定したテキストの幅を取得
        return context.measureText(text).width;
    }
};